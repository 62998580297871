import { Fragment, useRef } from "react"
import Navbar from "./components/Navbar.jsx"
import "./App.scss"
import Hero from "./components/Hero.jsx"
import heroImage from "./assets/hero-images.png"
import Button from "./components/Button.jsx"
import ContentAndImage from "./components/ContentAndImage.jsx"
import alertas from "./assets/alertas.png"
import rutaSegura from "./assets/ruta-segura.png"
import incidentes from "./assets/incidentes.png"
import benfits from "./assets/beneficios.json"
import IconAndContent from "./components/IconAndContent.jsx"
import onboardingImage from "./assets/onboarding-image.svg"
import playStore from "./assets/play-store.svg"
import appleStore from "./assets/apple-store.svg"
import Footer from "./components/Footer.jsx"

function App() {
  const heroContent = {
    title: "¿Qué es Cityi?",
    text: "Cityi es una app móvil que brinda un espacio para comunidades con usuarios dispuestos a colaborar entre sí, reportando situaciones de peligro con ubicaciones precisas en tiempo real.",
    imageUrl: heroImage,
  }
  const appRef = useRef(null)

  return (
    <div className="app vh-100" ref={appRef}>
      <div className="overlay"></div>
      <Navbar refApp={appRef} />
      <Hero
        title={heroContent.title}
        text={heroContent.text}
        imageUrl={heroContent.imageUrl}
        button={
          <Button
            customClass="transparent"
            text="Explora los beneficios"
            classToScroll="benefits"
          />
        }
      />
      <div className="container how-it-works">
        <div className="row">
          <div className="col-md-12 col-12"></div>
          <h2 className="subtitle">Cómo funciona</h2>
        </div>
      </div>
      <ContentAndImage
        title="Recibí alertas en"
        span=" tiempo real"
        imageAlignment="left"
        image={alertas}
        description="Notificaciones inmediatas y en tiempo real sobre situaciones peligrosas que estén ocurriendo en tu cercanía. La aplicación utiliza tecnología de geolocalización para enviar alertas precisas y relevantes para tu ubicación actual."
      />
      <ContentAndImage
        title="Elegí la ruta"
        span=" más segura"
        imageAlignment="right"
        image={rutaSegura}
        description="Seguí la ruta más segura para llegar a tu destino. La información actualizada en tiempo real sobre incidentes y situaciones peligrosas en las calles y carreteras cercanas, te permite tomar decisiones informadas sobre tu seguridad."
      />
      <ContentAndImage
        title="Reportá"
        span=" incidentes en tu zona"
        imageAlignment="left"
        image={incidentes}
        description="Informa a otros usuarios de la aplicación sobre situaciones peligrosas que estén ocurriendo en tu cercanía. Puedes reportar sobre delitos, emergencias médicas, accidentes de tráfico, entre otros. Al reportar un incidente, estarás contribuyendo a mantener a otros usuarios de la comunidad informados y seguros."
      />
      <section className="benefits">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-12">
              <h2 className="benefits-title">Beneficios</h2>
              <p className="benefits-description">
                Uno de nuestros atributos más fuertes se relaciona con que
                contaremos con una red de contactos y apoyo mutuo importante, la
                cual se desarrollará mediante una comunidad colaborativa.
              </p>
            </div>
          </div>
          <div className="row">
            {benfits.beneficios.map((benefit, index) => {
              return (
                <Fragment key={index}>
                  <IconAndContent
                    iconUrl={benefit.icon}
                    content={benefit.text}
                  />
                </Fragment>
              )
            })}
          </div>
        </div>
      </section>
      <section className="download-cta">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-12">
              <h2 className="download-cta-title">Seguridad en tus manos</h2>
              <p className="download-cta-description">
                Con su interfaz fácil de usar y funciones integrales, Cityi es
                imprescindible para cualquier persona que viaja a pie
                regularmente. Manténgase protegido y mejore su experiencia con
                esta innovadora aplicación móvil.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img className="onboarding-img" src={onboardingImage} alt="onboarding" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="wrapper d-flex flex-column align-items-center wrapper-download">
                <h2 className="wrapper-title">Descarga la aplicación</h2>
                <p className="wrapper-description">
                  No permitas que las preocupaciones por tu seguridad te impidan
                  disfrutar de tu viaje diario. Descarga Cityi hoy y toma el
                  control de tu seguridad personal.
                </p>
                <span className="text-white text-tag">Pronto disponible en:</span>
                <div className="store-buttons mt-5">
                  <a href="/" className="download-button">
                    <img
                      className="store-icon"
                      src={playStore}
                      alt="google play"
                    />
                  </a>
                  <a href="/" className="download-button">
                    <img
                      className="store-icon"
                      src={appleStore}
                      alt="app store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default App
