import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "./Navbar.scss"
import logo from "../assets/Cityi_Logo_White.svg"
import Button from "./Button"

const Navbar = ({ refApp }) => {
  const [ isFixed, setIsFixed ] = useState(false)
  const [ isMenuOpen, setIsMenuOpen ] = useState(false)

  useEffect(() => {
    const scrollable = refApp

    const handleScroll = () => {

      setIsFixed(scrollable.current.scrollTop > 0)
    }

    scrollable.current.addEventListener("scroll", handleScroll)

    return () => {
      scrollable.current.removeEventListener("scroll", handleScroll)
    }
  }, [ refApp ])

  const MobileNav = () => {
    return (
      <ul className={`mobile d-lg-none ${isMenuOpen ? "open" : ""}`}>
        <li className="nav-item ">
          <Button
            customClass="nav-btn"
            text="Sobre Cityi"
            classToScroll="hero"
          />
        </li>
        <li className="nav-item">
          <Button
            customClass="nav-btn"
            text="Cómo funciona"
            classToScroll="how-it-works"
          />
        </li>
        <li className="nav-item">
          <Button
            customClass="nav-btn"
            text="Beneficios"
            classToScroll="benefits"
          />
        </li>

      </ul>
    )
  }

  return (
    <nav className={`navbar ${isFixed ? "fixed" : ""}`}>
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <div className={`navbar-toggle ${isMenuOpen ? "open" : ""}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <span className={`${isMenuOpen ? "open" : ""}`} />
          <span className={`${isMenuOpen ? "open" : ""}`} />
          <span className={`${isMenuOpen ? "open" : ""}`} />
        </div>
        {isMenuOpen && <MobileNav />}
        <ul className={`navbar-nav d-none d-md-flex flex-row justify-content-md-end`}>
          <li className="nav-item ">
            <Button
              customClass="nav-btn"
              text="Sobre Cityi"
              classToScroll="hero"
            />
          </li>
          <li className="nav-item">
            <Button
              customClass="nav-btn"
              text="Cómo funciona"
              classToScroll="how-it-works"
            />
          </li>
          <li className="nav-item">
            <Button
              customClass="nav-btn"
              text="Beneficios"
              classToScroll="benefits"
            />
          </li>
          <li className="nav-item d-none">
            <Button
              customClass="nav-btn"
              text="Descarga"
              classToScroll="wrapper-download"
            />
          </li>
        </ul>
        <Button
          customClass="accent-blue d-none d-md-block"
          text="Descarga"
          classToScroll="wrapper-download"
        />
      </div>
    </nav>
  )
}

export default Navbar
