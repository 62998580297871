import React from "react"
import "./ContentAndImage.scss"

const ContentAndImage = ({
  title,
  span,
  description,
  imageAlignment,
  image,
}) => {
  return (
    <section className="content-image">
      <div className="container">
        <div
          className={`row ${
            imageAlignment === "right" ? "justify-content-end" : ""
          }`}>
          <div
            className={`col-12 col-md-5 d-flex align-items-center ${
              imageAlignment === "left" ? "order-md-1" : ""
            }`}>
            <h2 className="title">
              {title}
              <span>{span}</span>
              <p className="description mt-4">{description}</p>
            </h2>
          </div>
          <div className="col-12 col-md-4">
            <img className="mt-4" src={image} alt="mockup" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentAndImage
