import React from "react"
import "./Footer.scss"
import logo from "../assets/Cityi_Logo_White.svg"
import { Link } from "react-router-dom"
import { ReactComponent as PlayStore } from "../assets/play-store.svg"
import { ReactComponent as AppleStore } from "../assets/apple-store.svg"
import { ReactComponent as Facebook } from "../assets/facebook.svg"
import { ReactComponent as Instagram } from "../assets/instagram.svg"

const Footer = () => {
  const scrollToSection = (sectionClass) => {
    const section = document.getElementsByClassName(`${sectionClass}`)[0]

    if (section) {
      section.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 p-md-0 ">
            <img src={logo} alt="footer logo" />
          </div>
        </div>
        <div className="row row-items">
          <div className="col-md-6">
            <ul className="footer-items d-flex justify-content-between">
              <li className="footer-item">
                <Link
                  to={"/"}
                  onClick={(event) => {
                    event.preventDefault()
                    scrollToSection("hero")
                  }}>
                  Sobre Cityi
                </Link>
              </li>
              <li className="footer-item">
                <Link
                  to={"/"}
                  onClick={(event) => {
                    event.preventDefault()
                    scrollToSection("how-it-works")
                  }}>
                  Cómo funciona
                </Link>
              </li>
              <li className="footer-item">
                <Link
                  to={"/"}
                  onClick={(event) => {
                    event.preventDefault()
                    scrollToSection("benefits")
                  }}>
                  Beneficios
                </Link>
              </li>
              <li className="footer-item">
                <Link>Contáctanos</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 d-flex justify-content-center justify-content-md-end align-items-center">
            <PlayStore className="app-icon me-3" />
            <AppleStore className="app-icon" />
          </div>
        </div>
        <div className="row row-rights">
          <div className="col-md-6 order-1 order-md-0 mt-5 mt-md-0">
            <p className="rights text-center text-md-start">All Rights Reserved © Cityi 2023</p>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end justify-content-center">
            <a href="/" className="me-5">
              <Facebook />
            </a>
            <a href="/">
              <Instagram />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
