import React from "react"
import "./Button.scss"

const Button = ({ customClass, text, classToScroll }) => {
  const handleScroll = () => {
    const section = document.getElementsByClassName(`${classToScroll}`)[0]
    section.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <button className={`btn ${customClass}`} onClick={handleScroll}>
      {text}
    </button>
  )
}

export default Button
