import React from "react"
import "./Hero.scss"

function Hero({ title, text, button, imageUrl }) {
  return (
    <section className="hero" id="hero-1">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <h2 className="title">{title}</h2>
            <p className="description mb-md-5 mt-md-4">{text}</p>
            {button}
          </div>
          <div className="col-md-6 col-12">
            <img className="img" src={imageUrl} alt="mockup" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
