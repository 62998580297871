import React from "react"
import "./IconAndContent.scss"

const IconAndContent = ({ iconUrl, content }) => {
  const icon = require(`../assets/${iconUrl}`)

  return (
    <div className="col-md-4 col-12 icon-content d-flex flex-column align-items-center">
      <img src={icon} alt="icon-benefit" className="icon"/>
      <p className="description">{content}</p>
    </div>
  )
}

export default IconAndContent
